import axios from 'axios';

export async function fetchProjectDetails(): Promise<{
  tge: string | null;
  totalSupply: number;
  airdropAllocation: number;
  eligibleAmount: number;
  merkleRoot: string | undefined;
  totalAmount: number | undefined;
  totalUsers: number | undefined;
  startDate: string;
  endDate: string;
  tokenDecimals: number;
}> {
  const response = await axios.get(
    `${process.env.NEXT_PUBLIC_API_BASE_URL}/projects`,
    {
      params: {
        token_symbol: `${process.env.NEXT_PUBLIC_TOKEN_SYMBOL}`,
      },
    },
  );

  return {
    tge: response.data.tge,
    totalSupply: response.data.total_supply,
    airdropAllocation: response.data.airdrop_allocation,
    eligibleAmount: response.data.eligible_amount,
    merkleRoot: response.data.merkle_root?.root,
    totalAmount: response.data.merkle_root?.total_amount,
    totalUsers: response.data.merkle_root?.total_users,
    startDate: response.data.start_date,
    endDate: response.data.end_date,
    tokenDecimals: response.data.token_decimals,
  };
}
