import React, { useEffect, useState } from 'react';

import Image from 'next/image';
import Link from 'next/link';

import Logo from '@/assets/logo.png';
import LaunchpadLogo from '@/assets/launchpad-logo.png';
import ConnectWallet from '@/components/connect-wallet';
import useMobileDetection from '@/root/src/hooks/use-mobile-detection';
import { Box, Stack, Typography } from '@mui/material';

const DesktopHeader = () => {
  const [isClient, setIsClient] = useState(false);
  const tokenSymbol = process.env.NEXT_PUBLIC_TOKEN_SYMBOL;
  const isSolCatToken = tokenSymbol == 'SOLCAT' || !tokenSymbol;

  useEffect(() => {
    setIsClient(true);
  }, []);

  return (
    <Stack
      direction="row"
      className={`flex justify-between items-center ${
        isSolCatToken ? 'px-10 py-6' : 'pt-6 px-9 pb-3'
      } `}
    >
      <Stack direction="row" spacing={2}>
        <Link href="https://catsolhat.com/">
          <Image src={LaunchpadLogo} alt="launchpad-logo" height={60} />
        </Link>
      </Stack>
      {/* <Stack direction="row" spacing={2}>
        <Link href="/admin">Admin</Link>
      </Stack> */}
      {isClient && <ConnectWallet />}
    </Stack>
  );
};

const MobileHeader = () => {
  const [isClient, setIsClient] = useState(false);

  useEffect(() => {
    setIsClient(true);
  }, []);

  return (
    <Stack
      direction="row"
      className="flex justify-between items-center px-5 py-5"
    >
      <Image src={Logo} alt="logo" width={35} height={35} />
      {isClient && <ConnectWallet />}
    </Stack>
  );
};

const Header = () => {
  const isMobile = useMobileDetection();
  return (
    <Box className="fixed top-0 md:py-0 bg-black w-full z-[100]">
      {isMobile ? <MobileHeader /> : <DesktopHeader />}
    </Box>
  );
};

export default Header;
