import Logo from '@/assets/logo.png';
import Telegram from '@/assets/svgs/telegram.svg';
import TelegramBlack from '@/assets/svgs/telegram-black.svg';
import X from '@/assets/svgs/x.svg';
import XBlack from '@/assets/svgs/x-black.svg';
import Youtube from '@/assets/svgs/youtube.svg';
import YoutubeBlack from '@/assets/svgs/youtube-black.svg';
import Web from '@/assets/svgs/web.svg';
import MediumBlack from '@/assets/svgs/medium-black.svg';
import TikTokBlack from '@/assets/svgs/tiktok-black.svg';
import DiscordBlack from '@/assets/svgs/discord-black.svg';

export const IconName = {
  WEB: 'web',
  MEDIUM_BLACK: 'medium-black',
  TIKTOK_BLACK: 'tiktok-black',
  TELEGRAM: 'telegram',
  TELEGRAM_BLACK: 'telegram-black',
  X: 'x',
  X_BLACK: 'x-black',
  YOUTUBE: 'youtube',
  YOUTUBE_BLACK: 'youtube-black',
  LOGO: 'logo',
  DISCORD_BLACK: 'discord-black',
};

export const staticImportIcon = (iconName: string) => {
  switch (iconName) {
    case IconName.WEB:
      return Web;
    case IconName.MEDIUM_BLACK:
      return MediumBlack;
    case IconName.TIKTOK_BLACK:
      return TikTokBlack;
    case IconName.TELEGRAM:
      return Telegram;
    case IconName.TELEGRAM_BLACK:
      return TelegramBlack;
    case IconName.X:
      return X;
    case IconName.X_BLACK:
      return XBlack;
    case IconName.YOUTUBE:
      return Youtube;
    case IconName.YOUTUBE_BLACK:
      return YoutubeBlack;
    case IconName.DISCORD_BLACK:
      return DiscordBlack;
    default:
      return Logo;
  }
};
