import useMobileDetection from '@/root/src/hooks/use-mobile-detection';
import { Box, Stack } from '@mui/material';

import Footer from './footer';
import Header from './header';

interface MainLayoutProps extends HocProps {
  showFooter?: boolean;
}
export const MainLayout = ({
  children,
  showFooter = true,
}: MainLayoutProps) => {
  const tokenSymbol = process.env.NEXT_PUBLIC_TOKEN_SYMBOL;
  const isSolCatToken = tokenSymbol == 'SOLCAT' || !tokenSymbol;
  const isMobile = useMobileDetection();
  const paddingStyle = isMobile ? 'px-3 pt-24' : 'px-6 pt-28';
  return (
    <Stack className="h-full" justifyContent="space-between">
      <Header />
      <Box
        className={`w-full ${paddingStyle} bg-black min-h-dvh overflow-x-clip`}
      >
        {children}
      </Box>
      {showFooter && !isSolCatToken && <Footer />}
    </Stack>
  );
};
