import Image from 'next/image';

import useTrans from '@/root/src/hooks/use-trans';
import { Fade, Link, Stack, Tooltip, Typography } from '@mui/material';

import useMobileDetection from '../../hooks/use-mobile-detection';
import { IconName, staticImportIcon } from '../../utils/static-import-icon';
import CarouselText from '../components/solcat-carouse';

const SocialButton = () => {
  const isMobile = useMobileDetection();

  return (
    <Stack
      direction="row"
      gap={isMobile ? 2 : 3}
      className={`${isMobile ? 'px-6 py-8' : 'px-12 py-12'}`}
      justifyContent="center"
      alignItems="center"
      spacing="2px"
    >
      <Stack className="social-button-stack">
        <Tooltip title="Youtube" placement="top" TransitionComponent={Fade}>
          <Link
            data-testid="social-link-youtube"
            href="https://www.youtube.com/@catsolhat"
            target="_blank"
            rel="noreferrer"
            height={40}
          >
            <Image
              src={staticImportIcon(IconName.YOUTUBE_BLACK)}
              alt={IconName.YOUTUBE_BLACK}
              width={40}
              height={40}
            />
          </Link>
        </Tooltip>
      </Stack>
      <Stack className="social-button-stack">
        <Tooltip title="TikTok" placement="top" TransitionComponent={Fade}>
          <Link
            data-testid="social-link-tiktok"
            href="https://www.tiktok.com/@catsolhat"
            target="_blank"
            rel="noreferrer"
            height={40}
          >
            <Image
              src={staticImportIcon(IconName.TIKTOK_BLACK)}
              alt={IconName.TIKTOK_BLACK}
              width={40}
              height={40}
            />
          </Link>
        </Tooltip>
      </Stack>
      <Stack className="social-button-stack">
        <Tooltip title="Medium" placement="top" TransitionComponent={Fade}>
          <Link
            data-testid="social-link-medium"
            href="https://catsolhat.medium.com/"
            target="_blank"
            rel="noreferrer"
            height={40}
          >
            <Image
              src={staticImportIcon(IconName.MEDIUM_BLACK)}
              alt={IconName.MEDIUM_BLACK}
              width={40}
              height={40}
            />
          </Link>
        </Tooltip>
      </Stack>
      <Stack className="social-button-stack">
        <Tooltip title="Telegram" placement="top" TransitionComponent={Fade}>
          <Link
            data-testid="social-link-telegram"
            href="https://t.me/catsolhat1"
            target="_blank"
            rel="noreferrer"
            height={40}
          >
            <Image
              src={staticImportIcon(IconName.TELEGRAM_BLACK)}
              alt={IconName.TELEGRAM_BLACK}
              width={40}
              height={40}
            />
          </Link>
        </Tooltip>
      </Stack>
      <Stack className="social-button-stack">
        <Tooltip title="X" placement="top" TransitionComponent={Fade}>
          <Link
            data-testid="social-link-x"
            href="https://x.com/catsolhat"
            target="_blank"
            rel="noreferrer"
            height={40}
          >
            <Image
              src={staticImportIcon(IconName.X_BLACK)}
              alt={IconName.X_BLACK}
              width={40}
              height={40}
            />
          </Link>
        </Tooltip>
      </Stack>
      <Stack className="social-button-stack">
        <Tooltip title="Discord" placement="top" TransitionComponent={Fade}>
          <Link
            data-testid="social-link-discord"
            href="https://discord.gg/dVytU8NJsf"
            target="_blank"
            rel="noreferrer"
            height={40}
          >
            <Image
              src={staticImportIcon(IconName.DISCORD_BLACK)}
              alt={IconName.DISCORD_BLACK}
              width={40}
              height={40}
            />
          </Link>
        </Tooltip>
      </Stack>
    </Stack>
  );
};

const Footer = () => {
  const trans = useTrans();
  const isMobile = useMobileDetection();

  return (
    <>
      <Stack
        className={`${
          isMobile ? 'mx-3 py-4 rounded-[20px]' : 'mx-6 py-8 rounded-[40px]'
        } w-auto bg-gradient-footer mt-2 `}
        gap={isMobile ? 2 : 3}
      >
        <CarouselText />
        <SocialButton />
        <Stack
          direction="row"
          justifyContent="center"
          spacing={4}
          alignItems="center"
          className={`${isMobile ? 'px-8 py-6' : 'px-12 py-8'}`}
        >
          <Typography className="text-[14px] leading-5">
            © 2024 CATSOLHAT
          </Typography>
        </Stack>
      </Stack>
    </>
  );
};

export default Footer;
