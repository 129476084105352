import { useCallback, useEffect, useMemo, useState } from 'react';

import { useWallet } from '@solana/wallet-adapter-react';
import { useWalletModal } from '@solana/wallet-adapter-react-ui';

import { WalletMultiButtonViewProps } from './types';
import View from './view';
import { Connection, PublicKey } from '@solana/web3.js';
import {
  Token,
  TOKEN_PROGRAM_ID,
  ASSOCIATED_TOKEN_PROGRAM_ID,
} from '@solana/spl-token';
import { useSolana } from '../../providers/solana';

const Container = () => {
  const {
    connected,
    connecting,
    disconnect,
    disconnecting,
    publicKey,
    wallet,
  } = useWallet();
  const { connection, mintPublicKey } = useSolana();
  const { setVisible } = useWalletModal();

  const [menuAnchorEl, setMenuAnchorEl] = useState<HTMLElement>();
  const [showCopiedMenuItem, setShowCopiedMenuItem] = useState(false);

  const isWalletConnected = connected && publicKey && wallet;
  const [solCatBalance, setSolCatBalance] = useState(0);

  useEffect(() => {
    if (!publicKey || !connection) return;
    const fetchTokenBalance = async () => {
      try {
        const ata = await Token.getAssociatedTokenAddress(
          ASSOCIATED_TOKEN_PROGRAM_ID,
          TOKEN_PROGRAM_ID,
          mintPublicKey,
          publicKey,
        );
        const ataInfo = await connection.getParsedAccountInfo(ata);
        let balance = 0;
        if (ataInfo && ataInfo.value && ataInfo.value.data) {
          balance =
            (ataInfo.value.data as any).parsed?.info?.tokenAmount?.uiAmount ||
            0;
        }
        setSolCatBalance(balance);
      } catch (error) {
        console.error('Error fetching token balance:', error);
      }
    };

    fetchTokenBalance();
  }, [publicKey]);

  const minifyAddress = (address: string, length = 4) => {
    if (address.length <= length * 2) {
      return address;
    }

    const firstPart = address.slice(0, length);
    const lastPart = address.slice(-length);
    return `${firstPart}...${lastPart}`;
  };

  const handleCopyAddress = useCallback(async () => {
    if (publicKey) {
      await navigator?.clipboard?.writeText(publicKey.toBase58());
      setShowCopiedMenuItem(true);
      setTimeout(() => setShowCopiedMenuItem(false), 600);
    }
  }, [publicKey]);

  useEffect(() => {
    setMenuAnchorEl(undefined);
  }, [isWalletConnected]);

  const menuItems = useMemo(
    () =>
      isWalletConnected
        ? [
            {
              disabled: showCopiedMenuItem,
              keepMenuOpenAfterClick: true,
              onClick: showCopiedMenuItem ? undefined : handleCopyAddress,
              testId: 'btn-copy-wallet-address',
              text: showCopiedMenuItem ? 'Copied' : 'Copy Address',
            },
            {
              onClick: () => setVisible(true),
              testId: 'btn-change-wallet',
              text: 'Change Wallet',
            },
            {
              onClick: disconnect,
              testId: 'btn-disconnect-wallet',
              text: 'Disconnect',
            },
          ]
        : [],
    [
      disconnect,
      handleCopyAddress,
      isWalletConnected,
      showCopiedMenuItem,
      setVisible,
      'Copied',
      'Copy Address',
      'Disconnect',
    ],
  );

  const buttonLabel = isWalletConnected
    ? minifyAddress(publicKey.toBase58())
    : connecting
    ? 'Connecting...'
    : disconnecting
    ? 'Disconnecting...'
    : 'Connect Wallet';

  const viewProps = useMemo<WalletMultiButtonViewProps>(
    () => ({
      icon: wallet?.adapter.icon,
      loading: connecting || disconnecting,
      onClick: (e) =>
        isWalletConnected ? setMenuAnchorEl(e.currentTarget) : setVisible(true),
      walletInfo: buttonLabel,
      balance: solCatBalance,
      menuAnchorEl,
      menuItems,
      onMenuClose: () => setMenuAnchorEl(undefined),
    }),
    [
      buttonLabel,
      connecting,
      disconnecting,
      isWalletConnected,
      menuAnchorEl,
      menuItems,
      setVisible,
      wallet?.adapter.icon,
      solCatBalance,
    ],
  );

  return <View {...viewProps} />;
};

export default Container;
