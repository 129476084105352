import { useQuery } from 'react-query';

import { PublicKey } from '@solana/web3.js';

import { useSolana } from '../providers/solana';

interface DistributorData {
  version: number;
  root: Uint8Array;
  mint: PublicKey;
  tokenVault: PublicKey;
  maxTotalClaim: number;
  maxNumNodes: number;
  totalAmountClaimed: number;
  numNodesClaimed: number;
  startTs: number;
  endTs: number;
  clawbackReceiver: PublicKey;
  admin: PublicKey;
}

export const useDistributorData = () => {
  const { distributorClient, distributor } = useSolana();

  const fetchDistributorData = async (): Promise<DistributorData | null> => {
    if (!distributorClient || !distributor) return null;
    const fetchedData =
      await distributorClient.ctx.program.account.merkleDistributor.fetchNullable(
        distributor.key,
      );
    if (!fetchedData) return null;
    return fetchedData as unknown as DistributorData;
  };

  return useQuery(['distributorData', distributor?.key], fetchDistributorData, {
    enabled: !!distributor?.key,
    staleTime: 1 * 60 * 1000,
  })
};
