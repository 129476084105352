import React from 'react';
import { Box, keyframes, Typography } from '@mui/material';
import useMobileDetection from '../../hooks/use-mobile-detection';

const slide = keyframes`
  0% {
    transform: translateX(100%);
  }
  100% {
    transform: translateX(-100%);
  }
`;

const CarouselText: React.FC = () => {
  const isMobile = useMobileDetection();

  return (
    <Box className="relative">
      <Box
        sx={{
          display: 'flex',
          position: 'relative',
          justifyContent: 'center',
          alignItems: 'center',
          animation: `${slide} 100s linear infinite`,
        }}
        className="p-1"
      >
        {Array.from({ length: 24 }).map((_, index) => (
          <Typography
            key={index}
            className={`${
              isMobile ? 'text-[24px]' : 'text-[56px] leading-[72px]'
            } text-black font-cc`}
          >
            {index % 2 === 0 ? '$SOLCAT' : <span>&nbsp;·&nbsp;</span>}
          </Typography>
        ))}
      </Box>
    </Box>
  );
};

export default CarouselText;
