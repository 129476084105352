import axios from 'axios';

import { PublicKey } from '@solana/web3.js';

export async function fetchMerkleRoot(): Promise<{
  merkleRoot: string;
  totalAmount: number;
  totalUsers: number;
}> {
  const response = await axios.get(
    `${process.env.NEXT_PUBLIC_API_BASE_URL}/merkle_distributor/merkle_root`,
  );
  return {
    merkleRoot: response.data.root,
    totalAmount: response.data.total_amount,
    totalUsers: response.data.total_users,
  };
}

export async function fetchMerkleProof(publicKey: PublicKey | null): Promise<{
  proof: string[];
  index: number;
  amount: number;
}> {
  if (!publicKey) {
    throw new Error('Public key is null');
  }

  const response = await axios.get(
    `${process.env.NEXT_PUBLIC_API_BASE_URL}/merkle_distributor/merkle_proof`,
    {
      params: {
        wallet_address: publicKey.toBase58(),
        token_symbol: process.env.NEXT_PUBLIC_TOKEN_SYMBOL,
      },
    },
  );

  if (response.status !== 200) {
    console.warn('Failed to fetch proof data, status:', response.status);
    return {
      proof: [],
      index: 0,
      amount: 0,
    };
  }

  return {
    proof: response.data.proof,
    index: response.data.index,
    amount: response.data.amount,
  };
}
