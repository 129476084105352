// eslint-disable-next-line @typescript-eslint/no-explicit-any
const locale: { [key: string]: any } = {
  BODO: {
    website_url: 'https://boopdoge.com/',
    telegram_url: 'https://t.me/BoopDoge_Token',
    x_url: 'https://x.com/BoopDoge_Token',
    guide_url:
      'https://catsolhat.medium.com/introducing-the-first-catsolhat-launchpad-project-boopdoge-4e136347e53f',
    description:
      '<p>BoopDoge is a community-driven meme coin inspired by the urge to boop a dog on the nose. \
      Our mission is to help passionate dog lovers and crypto enthusiasts achieve financial freedom.</p> \
      <p>BoopDoge will be a flagship for emerging DeFi and meme culture on Solana. \
      This is part of a larger plan to strengthen the creator culture on Solana as BoopDoge continues to integrate into the broader ecosystem.</p>',
    eligibility: (eligibleAmount: number) =>
      `<p>Average holding of at <span style="color:#6dfcd1">least ${eligibleAmount} SOLCAT</span> (based on two snapshots). \
      The first snapshot was taken on 1st July. The second snapshot will be taken at a random moment between 6th and 8th July.</p>`,
    token_name: 'BoopDoge',
    blockchain: 'Solana',
  },
  SOFAC: {
    website_url: 'https://sofacat.click/',
    telegram_url: 'https://t.me/SofaCatSPL',
    x_url: 'https://x.com/SofaCatSPL',
    guide_url:
      'https://catsolhat.medium.com/introducing-the-next-launchpad-project-sofacat-9a498f0865a7',
    description:
      '<p>Every cat loves a good sofa, and so does the SofaCat! With $SOFAC, \
      comfort and profit go hand in hand - relax, enjoy, and watch your crypto grow.</p>',
    eligibility: (eligibleAmount: number) =>
      `<p>Hold at <span style="color:#6dfcd1">least ${eligibleAmount} $SOLCAT</span> at the snapshot on September 27th.</p>`,
    token_name: 'SofaCat',
    blockchain: 'Solana',
  },
  OIIA: {
    website_url: 'https://oiiaoiiasol.com/',
    telegram_url: 'https://t.me/oiia_oiia_community',
    x_url: 'https://x.com/oiia_oiia_onSol',
    guide_url:
      'https://x.com/catsolhat/status/1854521247759208769',
    description:
      '<p>OiiaOiia is a dynamic memecoin, featuring a quirky cat caught in endless spin excitement!</p>',
    eligibility: (eligibleAmount: number) =>
      `<p>Stake $SOLCAT to accumulate <span style="color:#6dfcd1">at least ${eligibleAmount} Launchpad Credits (LC)</span> by the snapshot on November 18.`,
    token_name: 'OiiaOiia',
    blockchain: 'Solana',
  },
  CATIVA: {
    website_url: 'https://cativa.lol/',
    telegram_url: 'https://t.me/cativasolana',
    x_url: 'https://x.com/cativasolana',
    guide_url:
      'https://x.com/catsolhat/status/1863952080312176786',
    description:
      '<p>Cats on Sativa are a bunch of chill cats enjoying life, purring, and vibing with the universe. Pure feline zen energy!</p>',
    eligibility: (eligibleAmount: number) =>
      `<p>Stake $SOLCAT to accumulate <span style="color:#6dfcd1">${eligibleAmount}+ Launchpad Credits (LC)</span> at the snapshot on December 9`,
    token_name: 'Cats on Savita',
    blockchain: 'Solana',
  },
  meta: {
    title: 'CatSolHat Super Airdrop',
    description:
      'Claim your SOLCAT airdrop: Early Contributors, Active Wallets on Solana DEXs, Saga Phone Buyers, Top NFT Collections Holders,...',
    thumbnail_url: '/thumbnail.png',
  },
};

export default locale;
