import { memo } from 'react';

import useMobileDetection from '@/root/src/hooks/use-mobile-detection';
import {
  Button,
  Divider,
  Menu,
  MenuItem,
  Stack,
  Typography,
} from '@mui/material';
import { useWallet } from '@solana/wallet-adapter-react';

import { WalletMultiButtonViewProps } from './types';
import { isSolcatToken } from '../../utils/helpers';

const View = ({
  walletInfo,
  balance,
  onClick,
  menuAnchorEl,
  menuItems,
  onMenuClose,
}: WalletMultiButtonViewProps) => {
  const { connected } = useWallet();
  const font = connected ? 'font-dm_mono normal-case' : 'font-cc';
  const isMobile = useMobileDetection();
  const fontSize = isMobile ? '' : 'text-xl';
  const tokenSymbol = process.env.NEXT_PUBLIC_TOKEN_SYMBOL;
  const isSolcat = isSolcatToken(tokenSymbol);

  return (
    <Stack>
      <Button
        className="px-6 py-3 bg-white bg-opacity-10 rounded-full justify-center items-center gap-2.5 inline-flex mx-auto hover:bg-opacity-20"
        onClick={onClick}
      >
        {connected ? (
          <Stack direction={'row'}>
            <Typography className={`text-white ${fontSize} ${font}`}>
              {balance} {isSolcat ? 'SOLCAT' : tokenSymbol}
            </Typography>
            <Divider
              sx={{ bgcolor: '#343434', width: '1px', height: '27px', mx: 1 }}
            />
            <Typography className={`text-white ${fontSize} ${font}`}>
              {walletInfo}
            </Typography>
          </Stack>
        ) : (
          <Typography className={`text-white ${fontSize} ${font}`}>
            {walletInfo}
          </Typography>
        )}
      </Button>

      {Boolean(menuItems.length) && (
        <Menu
          open={Boolean(menuAnchorEl)}
          onClose={onMenuClose}
          anchorEl={menuAnchorEl}
          anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
          transformOrigin={{ horizontal: 'right', vertical: 'top' }}
          MenuListProps={{
            className: 'bg-[#2c2d30] rounded-xl p-2 text-sm',
          }}
          classes={{ paper: 'bg-[#2c2d30] mt-1 rounded-md' }}
        >
          {menuItems.map(
            ({ disabled, keepMenuOpenAfterClick, onClick, testId, text }) => (
              <MenuItem
                key={text}
                data-testid={`header-wallet-${testId}`}
                disabled={disabled}
                className="bg-[#2c2d30] rounded-md text-sm px-4 py-3 text-white font-dm_mono hover:bg-[#3c3d40]"
                onClick={() => {
                  onClick?.();
                  !keepMenuOpenAfterClick && onMenuClose?.();
                }}
              >
                {text}
              </MenuItem>
            ),
          )}
        </Menu>
      )}
    </Stack>
  );
};

const memoView = memo(View);
memoView.displayName = 'ConnectWallet';
export default memoView;
